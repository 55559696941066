.about {
    background-color: #f8f8f8;
    margin-bottom: 2rem;
    padding: 6rem 1rem 0rem 1rem;
    
}

.about h2{
    font-size: 3rem;
    text-align: center;
}

.about .image-col{
    margin-top: 2rem;
    padding: 2rem;
}

.about .image-col img{
    margin: auto;
    display: block;
    width: 100%;
    border-radius: 50%;
}

.about .description-col{
    margin-top: 2rem;
    text-align: justify;
}

.about th{
    padding: 0.5rem 1rem 0.5rem 0rem;
    text-decoration: none;
    font-weight: normal;
}








