.NavBar {
    background-color: rgba(0,0,0, .9);
}















