.hero {
    background-color: var(--overlay);
    color: #fff;
    height: 100vh;
    width: 100%;
    position: relative;
}

.hero:before {
    content: '';
    background: url('../assets/images/hero.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.hero .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1100px;
    margin: auto;
    padding: 1rem;
    font-weight:700;
    font-size:4rem;
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
}

@media screen and (max-width:1010px) {
    .hero .content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 1100px;
        margin: auto;
        padding: 1rem;
        font-weight:700;
        font-size:2.5rem;
        text-align: center;
        padding-left: 10%;
        padding-right: 10%;
    }

}

.hero .typewriter {
    margin-bottom: 7rem;
}







