:root {
  --primary-color: #22748d;
  --primary-dark: #1897bd;
  --secondary-color: #41a4c3;
  --secondary-dark: #41a4c3;
  --overlay: rgba(0, 0, 0, .7);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  position: relative;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
  line-height: 1.2;
  background-color: #f8f8f8;
  min-height: 100vh;
  padding-bottom: 15rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: inherit;
}

.button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  cursor: pointer;
}

.button:hover {
  background: var(--primary-dark);
  border: 1px solid var(--primary-dark);
}

.line-center {
  display: block;
  width: 40px;
  height: 3px;
  margin: auto;
  margin-bottom: 2rem;
  background-color: var(--primary-color);
}

.line-left {
  display: block;
  width: 40px;
  height: 3px;
  margin-bottom: 15px;
  background-color: var(--primary-color);
}

.line-long {
  display: block;
  width: 100%;
  height: 3px;
  margin: auto;
  margin-top: 4rem;
  margin-bottom: 4rem;
  background-color: var(--primary-color);
  background-image: linear-gradient(left, white 2%, #E6E6E6 50%, white 98%);
  background-image: -o-linear-gradient(left, white 2%, #E6E6E6 50%, white 98%);
  background-image: -moz-linear-gradient(left, white 2%, #E6E6E6 50%, white 98%);
  background-image: -webkit-linear-gradient(left, white 2%, #E6E6E6 50%, white 98%);
  background-image: -ms-linear-gradient(left, white 2%, #E6E6E6 50%, white 98%);
  background-image: -webkit-gradient(linear, left bottom, right bottom, color-stop(0.02, white), color-stop(0.5, gray), color-stop(0.98, white));
}

.privacyImpressum {
  margin-top: 3rem;
  margin-bottom: 7rem;
}
.privacyImpressum h1 {
  margin-bottom: 2rem;
}

.privacyImpressum h2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}