.academics {
    margin: auto;
    padding: 5rem 0rem 6rem 0rem;
    width: 100%;
}

.academics h1{
    font-size: 4.5rem;
    text-align: center;
    margin-bottom: 2rem;
}

.academics h4{
    font-style: italic;
}

.academics p {
    text-align: justify;
}

.academics .image-col{
    padding: 0rem 2rem 2rem 2rem;
}

.academics .image-col img {
    margin: auto;
    display: block;
    width: 100%;
    box-shadow: 10px 10px 5px #ccc;
}

.links {
    display: block;
}

.academics button{
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.imgRight {
    flex-direction: row-reverse;
}

@media screen and (max-width:767px) {
    .imgRight {
        flex-direction: row;
    }

    .academics .image-col{
        padding: 0rem 2rem 4rem 2rem;
    }
}