.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 13rem;
    padding: 2rem 1rem;
    padding-bottom: 5rem;
    color: #eee;
    background-color: rgba(0,0,0, .9);
    margin: 0;
}


.footer .container {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.footer ul li{
    margin-left: -2.75rem;
    display: inline;
}

.footer li a {
    color: #fff;
}

.footer li a:hover {
    padding-bottom: 12px;
    border-bottom: 3px solid var(--secondary-color);
}




